<template>
	<section>
		<RecordTextContainer
			v-if="record.type === 'pre-anamnese' && record.value?.preAnamnese?.length"
			title="Pré-anamnese"
			:text="record.value.preAnamnese"
			copyable >
		</RecordTextContainer>

		<RecordTextContainer
			v-else-if="record.type === 'queixa-principal'"
			title="Queixa Principal"
			:text="record.value.queixaPrincipal" >
		</RecordTextContainer>

		<RecordTextContainer
			v-else-if="record.type === 'historia-da-doenca-atual'"
			title="Histórico da doença atual"
			:text="record.value.historicoDaDoencaAtual"
			copyable >
		</RecordTextContainer>

		<RecordTextContainer
			v-else-if="record.type === 'anamnese'"
			title="Anamnese"
			:text="record.value.anamnese"
			copyable >
		</RecordTextContainer>

    	<RecordAccordionTable
			v-else-if="record.type === 'antecedentes-oftalmologicos'"
			title="Antecedentes oftalmológicos"
			:headers="['Nome', 'Situação']"
			:items="record.value"
			:labels="['antecedente', 'situacao']"
    	></RecordAccordionTable>

    	<RecordAccordionTable
			v-else-if="record.type === 'antecedentes-pessoais'"
			title="Antecedentes pessoais"
			:headers="['Nome', 'Data']"
			:items="record.value"
			:labels="['antecedente', 'ano']"
    	></RecordAccordionTable>

    	<RecordAccordionTable
			v-else-if="record.type === 'antecedentes-familiares'"
			title="Antecedentes familiares"
			:headers="['Nome', 'Presente em']"
			:items="record.value"
			:labels="['antecedente', 'familiar']"
    	></RecordAccordionTable>

    	<RecordAccordionTable
			v-else-if="record.type === 'cirurgias-oftalmologicas-anteriores'"
			title="Cirurgias oftalmológicas anteriores"
			:headers="['Nome', 'Lateralidade' ,'Data']"
			:items="record.value"
			:labels="['cirurgia', 'lateralidade', 'data']"
    	></RecordAccordionTable>

    	<RecordTagsContainer
			v-else-if="record.type === 'habitos-de-vida'"
			title="Hábitos de vida"
			:tags="record.value"
    	></RecordTagsContainer>

    	<RecordAccordionTable
			v-else-if="record.type === 'medicamentos-oftalmologicos-em-uso'"
			title="Medicamentos oftalmológicos em uso"
			:headers="['Nome', '']"
			:items="record.value"
			:labels="['medicamento', 'emUso']"
    	></RecordAccordionTable>

    	<RecordAccordionTable
			v-else-if="record.type === 'outros-medicamentos-em-uso'"
			title="Outros medicamentos em uso"
			:headers="['Nome', '']"
			:items="record.value"
			:labels="['medicamento', 'emUso']"
    	></RecordAccordionTable>

    	<RecordAccordionTable
			v-else-if="record.type === 'alergias'"
			title="Alergias"
			:headers="['Nome', 'Comentário']"
			:items="record.value"
			:labels="['alergia', 'comentario']"
    	></RecordAccordionTable>

    	<RecordTextContainer
			v-else-if="record.type === 'informacoes-adicionais-anamnese'"
			title="Informações adicionais"
			:text="record.value.informacoesAdicionaisAnamnese"
    	></RecordTextContainer>

		<RecordAccordionTable
			v-else-if="record.type === 'informacoes-adicionais-exam'"
			title="Informações adicionais"
			:headers="['Informação', 'Profissional', 'Data/Horário']"
			:items="record.value"
			:labels="['informacaoAdicional', 'professional_name', 'updated_at']"
		/>

		<RecordTextContainer
			v-else-if="record.type === 'exame-fisico'"
			title="Exame físico"
			:text="record.value.exameFisico" >
    	</RecordTextContainer>

    	<div v-else-if="record.type === 'ectoscopia'">
			<RecordTextContainer
				title="Ectoscopia"
				:text="record.value.ectoscopia" >
			</RecordTextContainer>
    	</div>

    	<RecordEyeContainer
			v-else-if="record.type === 'avsc-acuidade-visual-sem-correcao'"
			title="AVSC - Acuidade visual sem correção"
			:leftEye="record.value.olhoEsquerdo"
			:rightEye="record.value.olhoDireito"
			eyeLabel
    	></RecordEyeContainer>

    	<RecordEyeContainer
			v-else-if="record.type === 'avcc-acuidade-visual-com-correcao'"
			title="AVCC - Acuidade visual com correção"
			:leftEye="record.value.olhoEsquerdo"
			:rightEye="record.value.olhoDireito"
			:olhoDireitoCilindrico="record.value.olhoDireitoCilindrico"
			:olhoDireitoEixo="record.value.olhoDireitoEixo"
			:olhoDireitoEsferico="record.value.olhoDireitoEsferico"
			:olhoEsquerdo="record.value.olhoEsquerdo"
			:olhoEsquerdoCilindrico="record.value.olhoEsquerdoCilindrico"
			:olhoEsquerdoEixo="record.value.olhoEsquerdoEixo"
			:olhoEsquerdoEsferico="record.value.olhoEsquerdoEsferico"
			:possuiAutorrefrator="record.value.possuiAutorrefrator"
        	eyeLabel
    	></RecordEyeContainer>

    	<RecordEyeContainer
			v-else-if="record.type === 'avppsc-acuidade-visual-para-perto-sem-correcao'"
			title="AVPPSC - Acuidade visual para perto sem correção"
			:leftEye="record.value.olhoEsquerdo"
			:rightEye="record.value.olhoDireito"
			eyeLabel
    	></RecordEyeContainer>

    	<RecordEyeContainer
			v-else-if="record.type === 'avppcc-acuidade-visual-para-perto-com-correcao'"
			title="AVPPCC - Acuidade visual para perto com correção"
			:leftEye="record.value.olhoEsquerdo"
			:rightEye="record.value.olhoDireito"
			eyeLabel
    	></RecordEyeContainer>

    	<RecordMultipleEyeContainer
			v-else-if="record.type === 'refracao-estatica'"
			title="Refração estática"
			:olhoDireitoAdicao="record.value.olhoDireitoAdicaoEst"
			:olhoDireitoCilindrico="record.value.olhoDireitoCilindricoEst"
			:olhoDireitoDP="record.value.olhoDireitoDPEst"
			:olhoDireitoAV="record.value.olhoDireitoAVEst"
			:olhoDireitoEixo="record.value.olhoDireitoEixoEst"
			:olhoDireitoEsferico="record.value.olhoDireitoEsfericoEst"
			:olhoEsquerdoAdicao="record.value.olhoEsquerdoAdicaoEst"
			:olhoEsquerdoCilindrico="record.value.olhoEsquerdoCilindricoEst"
			:olhoEsquerdoDP="record.value.olhoEsquerdoDPEst"
			:olhoEsquerdoAV="record.value.olhoEsquerdoAVEst"
			:olhoEsquerdoEixo="record.value.olhoEsquerdoEixoEst"
			:olhoEsquerdoEsferico="record.value.olhoEsquerdoEsfericoEst"
			:valoresNaPrescricao="record.value.usarValoresNaPrescricaoDeOculosEst"
			:observacao="record.value.observacao"
    	></RecordMultipleEyeContainer>

    	<RecordMultipleEyeContainer
			v-else-if="record.type === 'refracao-dinamica'"
			title="Refração dinâmica"
			:olhoDireitoAdicao="record.value.olhoDireitoAdicaoDin"
			:olhoDireitoCilindrico="record.value.olhoDireitoCilindricoDin"
			:olhoDireitoDP="record.value.olhoDireitoDPDin"
			:olhoDireitoAV="record.value.olhoDireitoAVDin"
			:olhoDireitoEixo="record.value.olhoDireitoEixoDin"
			:olhoDireitoEsferico="record.value.olhoDireitoEsfericoDin"
			:olhoEsquerdoAdicao="record.value.olhoEsquerdoAdicaoDin"
			:olhoEsquerdoCilindrico="record.value.olhoEsquerdoCilindricoDin"
			:olhoEsquerdoDP="record.value.olhoEsquerdoDPDin"
			:olhoEsquerdoAV="record.value.olhoEsquerdoAVDin"
			:olhoEsquerdoEixo="record.value.olhoEsquerdoEixoDin"
			:olhoEsquerdoEsferico="record.value.olhoEsquerdoEsfericoDin"
			:valoresNaPrescricao="record.value.usarValoresNaPrescricaoDeOculosDin"
			:observacao="record.value.observacao"
		></RecordMultipleEyeContainer>

    	<RecordMultipleEyeContainer
			v-else-if="record.type === 'autorrefracao-est'"
			title="Autorrefração estática"
			:olhoDireitoAdicao="record.value.olhoDireitoAdicao"
			:olhoDireitoCilindrico="record.value.olhoDireitoCilindrico"
			:olhoDireitoEixo="record.value.olhoDireitoEixo"
			:olhoDireitoEsferico="record.value.olhoDireitoEsferico"
			:olhoEsquerdoAdicao="record.value.olhoEsquerdoAdicao"
			:olhoEsquerdoCilindrico="record.value.olhoEsquerdoCilindrico"
			:olhoEsquerdoEixo="record.value.olhoEsquerdoEixo"
			:olhoEsquerdoEsferico="record.value.olhoEsquerdoEsferico"
    	></RecordMultipleEyeContainer>

    	<RecordMultipleEyeContainer
			v-else-if="record.type === 'autorrefracao-din'"
			title="Autorrefração dinâmica"
			:olhoDireitoAdicao="record.value.olhoDireitoAdicao"
			:olhoDireitoCilindrico="record.value.olhoDireitoCilindrico"
			:olhoDireitoEixo="record.value.olhoDireitoEixo"
			:olhoDireitoEsferico="record.value.olhoDireitoEsferico"
			:olhoEsquerdoAdicao="record.value.olhoEsquerdoAdicao"
			:olhoEsquerdoCilindrico="record.value.olhoEsquerdoCilindrico"
			:olhoEsquerdoEixo="record.value.olhoEsquerdoEixo"
			:olhoEsquerdoEsferico="record.value.olhoEsquerdoEsferico"
    	></RecordMultipleEyeContainer>

    	<RecordEyeContainer
			v-else-if="record.type === 'acuidade-visual-para-longe-apos-refracao'"
			title="Acuidade Visual para longe após Refração"
			:leftEye="record.value.olhoEsquerdo"
			:rightEye="record.value.olhoDireito"
			eyeLabel
    	></RecordEyeContainer>

    	<RecordEyeContainer
			v-else-if="record.type === 'acuidade-visual-para-perto-apos-refracao'"
			title="Acuidade Visual para perto após Refração"
			:leftEye="record.value.olhoEsquerdo"
			:rightEye="record.value.olhoDireito"
			eyeLabel
    	></RecordEyeContainer>

    	<div v-else-if="record.type === 'biomicroscopia'">
        <h3 v-if="Object.keys(record.value).some(key => key != 'simplified' && record.value[key])">
          Biomicroscopia
        </h3>
        <div v-if="record.value.simplified">
          <RecordEyeContainer
            :leftEye="record.value.olhoEsquerdo"
            :rightEye="record.value.olhoDireito"
          ></RecordEyeContainer>
        </div>
        <div v-else>
          <RecordEyeContainer
            v-if="record.value?.ciliosEPalpebrasEsquerdo || record.value?.ciliosEPalpebrasDireito"
            title="Cílios e pálpebras"
            :leftEye="record.value.ciliosEPalpebrasEsquerdo"
            :rightEye="record.value.ciliosEPalpebrasDireito"
          />

          <RecordEyeContainer
            v-if="record.value?.conjuntivaEsquerdo || record.value?.conjuntivaDireito"
            title="Conjuntiva"
            :leftEye="record.value.conjuntivaEsquerdo"
            :rightEye="record.value.conjuntivaDireito"
          />

          <RecordEyeContainer
            v-if="record.value?.corneaEsquerdo || record.value?.corneaDireito"
            title="Córnea"
            :leftEye="record.value.corneaEsquerdo"
            :rightEye="record.value.corneaDireito"
          />

          <RecordEyeContainer
            v-if="record.value?.camaraAnteriorEsquerdo || record.value?.camaraAnteriorDireito"
            title="Câmara anterior"
            :leftEye="record.value.camaraAnteriorEsquerdo"
            :rightEye="record.value.camaraAnteriorDireito"
          />

          <RecordEyeContainer
            v-if="record.value?.irisEsquerdo || record.value?.irisDireito"
            title="Íris"
            :leftEye="record.value.irisEsquerdo"
            :rightEye="record.value.irisDireito"
          />

          <RecordEyeContainer
            v-if="record.value?.pupilaEsquerdo || record.value?.pupilaDireito"
            title="Pupila"
            :leftEye="record.value.pupilaEsquerdo"
            :rightEye="record.value.pupilaDireito"
          />

          <RecordEyeContainer
            v-if="record.value?.cristalinoEsquerdo || record.value?.cristalinoDireito"
            title="Cristalino/Lente Intraocular"
            :leftEye="record.value.cristalinoEsquerdo"
            :rightEye="record.value.cristalinoDireito"
          />

          <RecordEyeContainer
            v-if="record.value?.vitreoEsquerdo || record.value?.vitreoDireito"
            title="Vítreo anterior"
            :leftEye="record.value.vitreoEsquerdo"
            :rightEye="record.value.vitreoDireito"
          />

          <RecordEyeContainer
            v-if="record.value?.biomicroscopiaDeFundoEsquerdo || record.value?.biomicroscopiaDeFundoDireito"
            title="Biomicroscopia de fundo"
            :leftEye="record.value.biomicroscopiaDeFundoEsquerdo"
            :rightEye="record.value.biomicroscopiaDeFundoDireito"
          />
        </div>
    	</div>
    	<section v-else-if="record.type === 'pressao-intraocular'">
			<RecordEyeExamContainer
				title="Pressão intraocular"
				v-for="(value, index) in record.value"
				:key=index
				:form="value"
			></RecordEyeExamContainer>
    	</section>

		<section v-if="record.type === 'curva-tensional'">
			<CurvaTensional
				:id="record.id"
				:form="record.value"
			/>
		</section>

    	<RecordEyeExamContainer
			v-else-if="record.type === 'tonometria'"
			title="Tonometria"
			:form="record"
    	></RecordEyeExamContainer>

		<RecordEyeContainer
			v-else-if="record.type === 'gonioscopia'"
			title="Gonioscopia"
			:leftEye="record.value.olhoEsquerdo"
			:rightEye="record.value.olhoDireito"
			eyeLabel
		/>

    	<RecordEyeContainer
        v-else-if="record.type === 'esquiascopia'"
        title="Esquiascopia"
        :leftEye="record.value.olhoEsquerdo"
        :rightEye="record.value.olhoDireito"
        eyeLabel
    	/>

    <div v-else-if="record.type === 'mapeamento-de-retina'">
      <h3 v-if="(record?.value?.simplified && (record.value?.olhoEsquerdo || record.value?.olhoDireito)) || hasMapeamentoRetinaValue">
        Mapeamento de retina/fundoscopia
      </h3>
      <div v-if="record?.value?.simplified && (record.value?.olhoEsquerdo || record.value?.olhoDireito)">
        <RecordEyeContainer
          :leftEye="record.value.olhoEsquerdo"
          :rightEye="record.value.olhoDireito"
        />
      </div>
      <div v-else>
        <RecordEyeContainer
          v-if="record.value?.vitreoEsquerdo || record.value?.vitreoEsquerdo"
          title="Vítreo"
          :leftEye="record.value.vitreoEsquerdo"
          :rightEye="record.value.vitreoEsquerdo"
        />

        <RecordEyeContainer
          v-if="record.value?.discoOpticoEsquerdo || record.value?.discoOpticoDireito"
          title="Disco óptico"
          :leftEye="record.value.discoOpticoEsquerdo"
          :rightEye="record.value.discoOpticoDireito"
        />

        <RecordEyeContainer
          v-if="record.value?.escavacaoEsquerdo || record.value?.escavacaoDireito"
          title="Escavação"
          :leftEye="record.value.escavacaoEsquerdo"
          :rightEye="record.value.escavacaoDireito"
        />

        <RecordEyeContainer
          v-if="record.value?.maculaEsquerdo || record.value?.maculaEsquerdo"
          title="Mácula"
          :leftEye="record.value.maculaEsquerdo"
          :rightEye="record.value.maculaEsquerdo"
        />

        <RecordEyeContainer
          v-if="record.value?.vasosEsquerdo || record.value?.vasosDireito"
          title="Vasos"
          :leftEye="record.value.vasosEsquerdo"
          :rightEye="record.value.vasosDireito"
        />

        <RecordEyeContainer
          v-if="record.value?.retinaEsquerdo || record.value?.retinaDireito"
          title="Retina"
          :leftEye="record.value.retinaEsquerdo"
          :rightEye="record.value.retinaDireito"
        />

        <RecordEyeContainer
          v-if="record.value?.hipoteseDiagnosticoEsquerdo || record.value?.hipoteseDiagnosticoDireito"
          title="Hipótese/Diagnóstico"
          :leftEye="record.value.hipoteseDiagnosticoEsquerdo"
          :rightEye="record.value.hipoteseDiagnosticoDireito"
        />
      </div>
		</div>

		<RecordEyeContainer
			v-else-if="record.type === 'motilidade-ocular-extrinseca'"
			title="Motilidade ocular extrinseca"
			:leftEye="record.value.olhoEsquerdo"
			:rightEye="record.value.olhoDireito"
			eyeLabel
		></RecordEyeContainer>

		<RecordTextContainer
			v-else-if="record.type === 'cover-test'"
			title="Cover Test"
			:text="record.value.coverTest" >
		</RecordTextContainer>

		<div v-else-if="record.type === 'reflexos'">
			<h3>Reflexos</h3>
			<RecordEyeContainer
				title="Fotomotor direto"
				:leftEye="record.value.fotomotorDiretoEsquerdo"
				:rightEye="record.value.fotomotorDiretoDireito"
				></RecordEyeContainer>
				<RecordEyeContainer
				title="Consensual"
				:leftEye="record.value.consensualEsquerdo"
				:rightEye="record.value.consensualDireito"
				></RecordEyeContainer>
				<RecordEyeContainer
				title="DPAR"
				:leftEye="record.value.dparEsquerdo"
				:rightEye="record.value.dparDireito"
			></RecordEyeContainer>
		</div>

		<RecordMultipleEyeContainer
			v-else-if="record.type === 'lensometria'"
			title="Lensometria"
			:olhoDireitoAdicao="record.value.olhoDireitoAdicao"
			:olhoDireitoCilindrico="record.value.olhoDireitoCilindrico"
			:olhoDireitoEixo="record.value.olhoDireitoEixo"
			:olhoDireitoEsferico="record.value.olhoDireitoEsferico"
			:olhoEsquerdoAdicao="record.value.olhoEsquerdoAdicao"
			:olhoEsquerdoCilindrico="record.value.olhoEsquerdoCilindrico"
			:olhoEsquerdoEixo="record.value.olhoEsquerdoEixo"
			:olhoEsquerdoEsferico="record.value.olhoEsquerdoEsferico">
		</RecordMultipleEyeContainer>

		<RecordTextContainer
			v-else-if="record.type === 'informacoes-adicionais'"
			title="Informações adicionais"
			:text="record.value.informacoesAdicionais">
		</RecordTextContainer>

		<RecordTextContainer
			v-else-if="record.type === 'campo-confrontacao'"
			title="Campo de confrontação"
			:text="record.value.campoConfrontacao">
		</RecordTextContainer>

		<RecordTextContainer
			v-else-if="record.type === 'teste-sensibilidade-mimica-facial'"
			title="Teste de sensibilidade mímica facial"
			:text="record.value.testeSensibilidadeMimicaFacial">
		</RecordTextContainer>

		<div v-else-if="record.type === 'biometria'">
			<h3 class="mt-3">Biometria</h3>
			<RecordEyeContainer
			v-for="(value, index) in filterValue(record.value) "
				:key=index
				:title="value?.title"
				:leftEye="value?.olhoEsquerdo"
				:rightEye="value?.olhoDireito"
			></RecordEyeContainer>
		</div>

		<RecordEyeContainer v-else-if="record.type === 'retinografia'"
			title="Retinografia"
			:leftEye="record.value.olhoEsquerdo"
			:rightEye="record.value.olhoDireito"
		></RecordEyeContainer>

		<RecordEyeTableContainer v-else-if="record.type === 'ceratometria'"
			title="Ceratometria"
			:item="record.value"
		></RecordEyeTableContainer>

		<RecordEyeContainer v-else-if="record.type === 'paquimetria'"
			title="Paquimetria"
			:leftEye="record.value.olhoEsquerdo"
			:rightEye="record.value.olhoDireito"
		></RecordEyeContainer>

		<RecordMultipleEyeContainer
			v-else-if="record.type === 'campo-visual'"
			title="Campo visual"
			:aparelho="record.value.aparelho"
			:olhoDireitoMd="record.value.olhoDireitoMd"
			:olhoEsquerdoMd="record.value.olhoEsquerdoMd"
			:olhoDireitoPsd="record.value.olhoDireitoPsd"
			:olhoEsquerdoPsd="record.value.olhoEsquerdoPsd"
			:laudoDireito="record.value.laudoDireito"
			:laudoEsquerdo="record.value.laudoEsquerdo"
			:observacao="record.value.observacao"
		></RecordMultipleEyeContainer>

		<RecordMultipleEyeContainer
			v-else-if="record.type === 'oct-tomografia-de-coerencia-optica-macula'"
			title="OCT (Tomografia de Coerência Óptica) de Mácula"
			:olhoDireito="record.value.olhoDireito"
			:observacaoDireito="record.value.observacaoDireito"
			:olhoEsquerdo="record.value.olhoEsquerdo"
			:observacaoEsquerdo="record.value.observacaoEsquerdo"
		/>

		<RecordEyeContainer v-else-if="record.type === 'oct-tomografia-de-coerencia-optica-nervo-optico'"
			title="OCT (Tomografia de Coerência Óptica) de Nervo Óptico"
			:leftEye="record.value.olhoEsquerdo"
			:rightEye="record.value.olhoDireito"
		></RecordEyeContainer>

		<RecordEyeContainer v-else-if="record.type === 'oct-tomografia-de-coerencia-optica-segmento-anterior'"
			title="OCT (Tomografia de Coerência Óptica) de Segmento Anterior"
			:leftEye="record.value.olhoEsquerdo"
			:rightEye="record.value.olhoDireito"
		></RecordEyeContainer>

		<RecordEyeContainer v-else-if="record.type === 'oct-a-tomografia-de-coerencia-optica'"
			title="OCT-A (Angiotomografia de Coerência Óptica)"
			:leftEye="record.value.olhoEsquerdo"
			:rightEye="record.value.olhoDireito"
		></RecordEyeContainer>

		<RecordEyeTableContainer v-else-if="record.type === 'topografia'"
			title="Topografia"
			:item="record.value"
		></RecordEyeTableContainer>

		<RecordEyeContainer v-else-if="record.type === 'tomografia-de-cornea-pentacam'"
			title="Tomografia de Córnea (Pentacam)"
			:leftEye="record.value.olhoEsquerdo"
			:rightEye="record.value.olhoDireito"
		></RecordEyeContainer>

		<RecordEyeTableContainer v-else-if="record.type === 'tomografia-de-cornea-galilei'"
			title="Tomografia de Córnea (Galilei)"
			:item="record.value"
		></RecordEyeTableContainer>


		<RecordEyeContainer v-else-if="record.type === 'microscopia-especular-de-cornea'"
			title="Microscopia Especular de Córnea"
			:leftEye="record.value.olhoEsquerdo"
			:rightEye="record.value.olhoDireito"
		></RecordEyeContainer>

		<RecordEyeContainer v-else-if="record.type === 'indocianinografia-icg'"
			title="Indocianinografia (ICG)"
			:leftEye="record.value.olhoEsquerdo"
			:rightEye="record.value.olhoDireito"
		></RecordEyeContainer>

		<RecordEyeContainer v-else-if="record.type === 'angiofluoresceinografia-afg'"
			title="Angiofluoresceinografia (AFG)"
			:leftEye="record.value.olhoEsquerdo"
			:rightEye="record.value.olhoDireito"
		></RecordEyeContainer>

		<RecordEyeContainer v-else-if="record.type === 'usg-ocular'"
			title="USG Ócular"
			:leftEye="record.value.olhoEsquerdo"
			:rightEye="record.value.olhoDireito"
		></RecordEyeContainer>

		<RecordEyeContainer v-else-if="record.type === 'eletrorretinograma-erg-de-campo-total'"
			title="Eletrorretinograma (ERG) de Campo Total"
			:leftEye="record.value.olhoEsquerdo"
			:rightEye="record.value.olhoDireito"
		></RecordEyeContainer>

		<RecordEyeContainer v-else-if="record.type === 'erg-multifocal'"
			title="ERG Multifocal"
			:leftEye="record.value.olhoEsquerdo"
			:rightEye="record.value.olhoDireito"
		></RecordEyeContainer>

		<RecordEyeContainer v-else-if="record.type === 'potencial-visual-evocado-pve'"
			title="Potencial Visual Evocado (PVE)"
			:leftEye="record.value.olhoEsquerdo"
			:rightEye="record.value.olhoDireito"
		></RecordEyeContainer>

		<RecordEyeContainer v-else-if="record.type === 'pve-varredura'"
			title="PVE varredura"
			:leftEye="record.value.olhoEsquerdo"
			:rightEye="record.value.olhoDireito"
		></RecordEyeContainer>

		<RecordAdditionalExam
			v-else-if="record.type.includes('exame-personalizado') "
			:title="record.value.customName"
			:items="record.value"
		></RecordAdditionalExam>

		<RecordAccordionTable
			v-else-if="record.type === 'dados-da-cirurgia'"
			title="Dados da cirurgia"
			:headers="['Nome', 'Lateralidade']"
			:items="record.value"
			:labels="['cirurgia', 'surgeryDataLaterality']"
		></RecordAccordionTable>


		<RecordEyeContainer v-else-if="record.type === 'potencial-acuidade-macular'"
			title="Potencial de Acuidade Macular (PAM)"
			:leftEye="record.value.olhoEsquerdo"
			:rightEye="record.value.olhoDireito"
		></RecordEyeContainer>

		<RecordAccordionTable
			v-else-if="record.type === 'anestesia'"
			title="Anestesia utilizada"
			:headers="['Tipo de anestesia utilizada', 'Anestesista']"
			:items="record.value"
			:labels="['anestesia', 'anestesista']"
		></RecordAccordionTable>

		<RecordAccordionTable
			v-else-if="record.type === 'produtos-utilizados'"
			title="Produtos utilizados"
			:headers="['Nome do produtos', 'Quantidade']"
			:items="record.value"
			:labels="['produto', 'quantidade']"
		></RecordAccordionTable>

		<RecordAccordionTable
			v-else-if="record.type === 'patologia-a-ser-tratada'"
			title="Patologia a ser tratada"
			:headers="['Patologia', 'Lateralidade', 'Comentário']"
			:items="record.value"
			:labels="['patologia', 'lateralidadePatologia', 'comentario']"
		></RecordAccordionTable>

		<RecordAccordionTable
			v-else-if="record.type === 'anotacao-cirurgica'"
			title="Anotação cirúrgica"
			:headers="['Anotação cirúrgica']"
			:items="record.value"
			:labels="['name', 'template']"
		></RecordAccordionTable>

		<RecordAccordionTable
			v-else-if="record.type === 'intercorrencias'"
			title="Intercorrências"
			:headers="['Intercorrências']"
			:items="record.value"
			:labels="['intercorrencias']"
		></RecordAccordionTable>

		<RecordAccordionTable
			v-else-if="record.type === 'hipotese-diagnostica'"
			title="Hipótese diagnóstica"
			:headers="['Nome']"
			:items="record.value"
			:labels="['hipoteseDiagnostica']"
		></RecordAccordionTable>

		<RecordAccordionTable
			v-else-if="record.type === 'cid'"
			title="CID"
			:headers="['CID adicionados', 'Predominante']"
			:items="record.value"
			:labels="['cid', 'predominante']"
		></RecordAccordionTable>

    	<RecordAccordionTable
			v-else-if="record.type === 'solicitacao-de-exames'"
			title="Solicitação de exames"
			:items="record.value"
			:labels="['exame', 'examLaterality' ,'comentario']"
		></RecordAccordionTable>

		<RecordCheckBox
			v-else-if="record.type === 'apa-avaliacao-pre-anestesica'"
			title="Solicitação de APA (Avaliação Pré Anestésica)"
			:checkboxes="record.value"
		></RecordCheckBox>

		<RecordAccordionTable
			v-else-if="record.type === 'solicitacao-de-cirurgia'"
			title="Solicitação de cirurgia"
			:headers="['Nome', 'Comentário']"
			:items="record.value"
			:labels="['cirurgia', 'comentario']"
		></RecordAccordionTable>

    <SolicitacaoDeTratamentoRecord
      v-else-if="record.type === 'solicitacao-de-tratamento'"
      :items="record.value"
      :otherInformations="record.value"
    />
		<RecordAccordionTable
			v-else-if="record.type === 'encaminhamento'"
			title="Encaminhamento"
			:headers="['Especialidade', 'Comentário']"
			:items="record.value"
			:labels="['encaminhamento', 'comentario']"
		></RecordAccordionTable>

		<RecordAccordionTable
			v-else-if="record.type === 'precricao-medicamentosa'"
			title="Prescrição Medicamentosa"
			:headers="['Nome do medicamento', 'Quantidade', 'Via', 'Posologia']"
			:items="record.value"
			:labels="['medicamento', 'quantidade', 'via', 'posologia']"
		></RecordAccordionTable>

		<RecordAccordionTable
			v-else-if="record.type === 'prescricao-medicamentosa-v2'"
			title="Prescrição Medicamentosa "
			:headers="['Nome do medicamento', 'Quantidade', 'Via', 'Posologia e orientação']"
			:items="record.value"
			:labels="['medicamento', 'quantidade', 'via', [{0: 'horarios', 1: 'intervalo', 2 :'tempo_uso', 3: 'orientacao', 4:'lateralidade'}]]"
		></RecordAccordionTable>

		<RecordGlassesLens
			v-else-if="record.type === 'oculos'"
			title="Prescrição de óculos"
			:conduta="record">
		</RecordGlassesLens>

		<RecordGlassesLens
			v-else-if="record.type === 'lentes-de-contato'"
			title="Prescrição de lentes de contato"
			:conduta="record">
		</RecordGlassesLens>

		<RecordAccordionTable
			v-else-if="record.type === 'orientacao-pos-operatoria'"
			title="Orientação ao paciente"
			:headers="['Nome']"
			:items="record.value"
			:labels="['orientacao', 'descricao']"
		></RecordAccordionTable>

		<RecordTextContainer
			v-else-if="record.type === 'mais-Informacoes'"
			title="Mais informações"
			:text="record.value.maisInformacoes" >
		</RecordTextContainer>


		<RecordTextContainer
			v-else-if="record.type === 'retorno'"
			title="Retorno"
			:text="record.value.retorno" >
		</RecordTextContainer>

		<div v-else-if="record.type === 'atestados-medicos'">
		<RecordCertificate
			v-bind:hasCertificate="record.value.atestado"
			v-bind:hasCompanionAttendanceDeclaration="record.value.declaracaoDeComparecimentoAcompanhante"
			:horarioChegada="record.value.horarioChegada"
			:horarioSaida="record.value.horarioSaida"
			v-bind:hasAttendanceDeclaration="record.value.declaracaoDeComparecimento"
			:cid="record.value.cid"
			:days="record.value.dias"
			:companionName="record.value.nomeAcompanhante"
			:companionDocument="record.value.cpfAcompanhante"
			:cpfPaciente="record.value.cpfPaciente"
			:maisInformacoes="record.value.maisInformacoes"
			:afastamento="record.value.afastamento"
		></RecordCertificate>
		</div>
		
		<div v-else-if="record.type === 'doctor-assistant-result'">
			<RecordAccordionTable
			@openHistoryModal = "openHistoryModal"
			title="Resultado do processamento da IA"
			:items="record.value"
			:labels="['resultadoDoctorAssistant']"
			:attendanceId = "record.attendance_id"
		></RecordAccordionTable>
		</div>

		<div v-else-if="record.type === 'laudo-medico'">
		<HistoryAppointmentDocsModal 
			:attendanceId ="attendanceId"
			@closeModal = "closeModal"
		/>
		<RecordAccordionTable
			@openHistoryModal = "openHistoryModal"
			title="Laudo médico"
			:headers="['Nome', 'Descrição']"
			:items="record.value"
			:labels="['laudo', 'descricao']"
			:attendanceId = "record.attendance_id"
			hasDocuments
		></RecordAccordionTable>


		</div>

		<div v-else-if="record.type === 'complemento-glaucoma'">
			<RecordGlaucoma 
				title="Medicamentos"
				:items="record.value"
			>
			</RecordGlaucoma>
		</div>

		<AttachedFiles
			v-else-if="record.type === 'anexar-arquivos'"
			:files="record.value"
		/>
  </section>
</template>

<script>
export default {
	components: {
		RecordTextContainer: () => import('@/components/MedicalRecords/RecordTextContainer'),
		RecordAccordionTable: () => import('@/components/MedicalRecords/RecordAccordionTable'),
		RecordEyeContainer: () => import('@/components/MedicalRecords/RecordEyeContainer'),
		RecordAdditionalExam: () => import('@/components/MedicalRecords/RecordAdditionalExam'),
		RecordCheckBox: () => import('@/components/MedicalRecords/RecordCheckBox'),
		RecordMultipleEyeContainer: () => import('@/components/MedicalRecords/RecordMultipleEyeContainer'),
		RecordTagsContainer: () => import('@/components/MedicalRecords/RecordTagsContainer'),
		RecordEyeExamContainer: () => import('@/components/MedicalRecords/RecordEyeExamContainer'),
		RecordCertificate: () => import('@/components/MedicalRecords/RecordCertificate'),
		RecordGlassesLens: () => import('@/components/MedicalRecords/RecordGlassesLens'),
		AttachedFiles: () => import('@/components/MedicalRecords/AttachedFiles'),
		SolicitacaoDeTratamentoRecord: () => import('./SolicitacaoDeTratamentoRecord'),
		CurvaTensional: () => import('./CurvaTensional'),
		RecordEyeTableContainer: () => import('@/components/MedicalRecords/RecordEyeTableContainer'),
		HistoryAppointmentDocsModal: () => import('@/components/General/HistoryAppointmentDocsModal'),
		RecordGlaucoma: () => import('./RecordGlaucoma')
  },
  props: {
    record: Object,
  },
  data() {
    return {
		attendanceId: null
	}
  },
  computed: {
    hasMapeamentoRetinaValue() {
      const properties = [
        'vitreoEsquerdo',
        'discoOpticoEsquerdo',
        'discoOpticoDireito',
        'escavacaoEsquerdo',
        'escavacaoDireito',
        'maculaEsquerdo',
        'vasosEsquerdo',
        'vasosDireito',
        'retinaEsquerdo',
        'retinaDireito',
        'hipoteseDiagnosticoDireito',
        'hipoteseDiagnosticoEsquerdo',
      ];
      return properties.some(property => this.record.value[property]);
    }
  },
  methods: {
    filterValue(value) {
      return value.filter(item=>{return item.olhoDireito || item.olhoEsquerdo })
    },
	openHistoryModal(id){
		this.attendanceId = id
		setTimeout(() => {
			this.$bvModal.show('history-appointment-docs-modal')
		}, 200);
	},
	closeModal(){
		this.attendanceId = null
	}
  }
}
</script>
